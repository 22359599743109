import React, {createRef, useEffect, useState} from "react";
// import Paper from "@material-ui/core/Paper";

import VectorLayer from "ol/layer/Vector";

import axios from "axios";
import "ol/ol.css";

import {max as d3Max, min as d3Min, range as d3Range, rollup as d3Rollup, sum as d3Sum,} from "d3-array";

import CommonWrapper from "../../../components/Pages/CommonWrapper";
import useStyles from "./styles";
import {breadcrumbs} from "./variables";
import {GlobalObject} from "../../../types/Type";
import {initMap, map} from "./geo-functions";
import CustomNivoBar from './CustomNivoBar';
import { Card, CardContent, CardHeader, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

const dataUrl = "/media/documents/pengolahan-air-limbah-simplified-header.json";

const layerKV : {[key: string]: VectorLayer} = {};

const DatasetView = () => {
  const classes = useStyles();
  const [basemap, setBasemap] = useState<number>(0)
  const handleChangeBasemap = (e: SelectChangeEvent) => setBasemap(parseInt(e.target.value))

  const [data, setData] = useState<GlobalObject[]>([])
  const [kecData, setKecData] = useState<GlobalObject[]>([])
  const [, setLayerKeyList] = useState<{key: string, label: string}[]>([])
  const mapContainerRef = createRef<HTMLDivElement>()
  const mapPopupRef = createRef<HTMLDivElement>()

  // load data dulu baru peta

  const getDesaWithStyle = (data: any[]) => {
    const rangeMin = d3Min(data, d => d['jumlah_perekaman_data']);
    const rangeMax = d3Max(data, d => d['jumlah_perekaman_data']);
    const range = d3Range(rangeMin, rangeMax, (parseFloat(rangeMax)-parseFloat(rangeMin))/3.0);
    range.push(rangeMax);

    const desaWithStyleList = data.map((d) => {
      for(let i=1; i<range.length; i++) {
        if(i > 1) {
          if(d['jumlah_perekaman_data'] > range[i-1] && d['jumlah_perekaman_data'] <= range[i]) {
            d['style_id'] = i-1;
          }
        } else {
          if(d['jumlah_perekaman_data'] >= range[i-1] && d['jumlah_perekaman_data'] <= range[i]) {
            d['style_id'] = i-1;
          }
        }
      }

      return [d['kode_kel'], d];
    });

    const desaWithStyle = Object.fromEntries(desaWithStyleList);

    return desaWithStyle;
  };

  useEffect(() => {
    axios
      .get(dataUrl, {})
      .then((result) => {
        const resultFromWeb: { [key: string]: string | number }[] = result.data;
        const columnsToSum = ["is_jamban", "is_septick", "is_mampu_septick", "is_mau_septick"];

        // Group by kelurahan
        const gByKel = d3Rollup(
          resultFromWeb,
          (v) => {
            const arraySum: (string | number)[][] = [
              ["kode_kel", v[0]["kode_kel"]],
              ["kec", v[0]["kec"]],
              ["kel", v[0]["kel"]],
              ["jumlah_perekaman_data", v.length],
            ];
            arraySum.push(...columnsToSum.map((col) => [col, d3Sum(v, (d: { [key: string]: string | number }) => +d[col])]));

            const row = Object.fromEntries(arraySum);

            return row;
          },
          (d) => d["kode_kel"]
        );

        const gByKelArray = Array.from(gByKel)
          .sort((a, b) => {
            if (a[1]["kec"] < b[1]["kec"]) {
              return -1;
            }
            if (a[1]["kec"] > b[1]["kec"]) {
              return 1;
            }
            return 0;
          })
          .map((item) => item[1]);

        setData(gByKelArray);

        // Group by kecamatan
        const gByKec = d3Rollup(
          resultFromWeb,
          (v) => {
            const arraySum: (string | number)[][] = [
              ["kode_kec", v[0]["kode_kel"]],
              ["kec", v[0]["kec"]],
              ["jumlah_perekaman_data", v.length],
            ];
            arraySum.push(...columnsToSum.map((col) => [col, d3Sum(v, (d: { [key: string]: string | number }) => +d[col])]));

            if (typeof arraySum[2][1] === "number" && typeof arraySum[3][1] === "number") {
              arraySum.push(["is_not_jamban", arraySum[2][1] - arraySum[3][1]]);
            }

            if (typeof arraySum[2][1] === "number" && typeof arraySum[4][1] === "number") {
              arraySum.push(["is_not_septick", arraySum[2][1] - arraySum[4][1]]);
            }

            const row = Object.fromEntries(arraySum);

            return row;
          },
          (d) => d["kec"]
        );

        const gByKecArray = Array.from(gByKec)
          .sort((a, b) => {
            if (a[1]["kec"] < b[1]["kec"]) {
              return -1;
            }
            if (a[1]["kec"] > b[1]["kec"]) {
              return 1;
            }
            return 0;
          })
          .map((item) => item[1]);

        setKecData(gByKecArray);
      })
      .catch(() => {});

    return () => {
      map.setTarget(undefined);
    };
  }, []);

  useEffect(() => {
    // https://observablehq.com/@danielkerrigan/sum-multiple-columns
    // https://www.tutorialspoint.com/can-someone-explain-to-me-what-the-plus-sign-is-before-the-variables-in-javascript
    if (data.length > 0) {
      const desaWithStyle = getDesaWithStyle(data);
      const refs : {[key: string]: React.RefObject<HTMLDivElement>} = {
        mapContainer: mapContainerRef,
        mapPopup: mapPopupRef,
      };
      initMap(map, desaWithStyle, layerKV, setLayerKeyList, refs, basemap);
    }
  }, [data, mapContainerRef, mapPopupRef, basemap]);

  useEffect(() => {
    // https://observablehq.com/@danielkerrigan/sum-multiple-columns
    // https://www.tutorialspoint.com/can-someone-explain-to-me-what-the-plus-sign-is-before-the-variables-in-javascript
    if (kecData.length > 0) {
    }
  }, [kecData]);

  return (
    <CommonWrapper path="/dataset" title="Dataset - Pengelolaan Air Limbah Domestik" breadcrumbs={breadcrumbs}>
      <Grid className={classes.root} container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Card className={classes.mapPopup} ref={mapPopupRef}>
          </Card>
          <Card className={classes.mapContainer}>
            <CardHeader title={<Grid container direction={`row`}>
                <Grid item sx={{flexGrow: 1,}}>
                  Peta Pengelolaan Air Limbah Domestik
                </Grid>
                <Grid item sx={{width: 200}}>
                  <FormControl fullWidth>
                    <InputLabel >Pilih Basemap</InputLabel>
                    <Select
                      id="demo-simple-select"
                      value={basemap.toString()}
                      label="Pilih Basemap"
                      onChange={handleChangeBasemap}
                      size={`small`}
                    >
                      <MenuItem value={0}>Imagery</MenuItem>
                      <MenuItem value={1}>Topo</MenuItem>
                      <MenuItem value={2}>Road</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
            </Grid>} />
            <div ref={mapContainerRef} id="mapContainer"></div>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6}>
            <Card>
              <CardHeader title="Jumlah Kepemilikan Jamban per Kecamatan" />
              <CardContent className={classes.chartContainer}>

                <CustomNivoBar 
                  data={kecData.map((item) => {
                    item['Memiliki jamban'] = item['is_jamban'];
                    item['Tidak memiliki jamban'] = item['is_not_jamban'];
                    return item;
                  })}
                  keys={['Memiliki jamban', 'Tidak memiliki jamban']}
                  indexBy="kec"
                  tooltip={(d) => (
                    <Grid container>
                      <Grid item xs={12}>
                        Kecamatan : <b>{d.data["kec"]}</b>
                      </Grid>
                      <Grid item xs={12}>
                        Kepemilikan Jamban : <b>{d.id === "is_jamban" ? "Ya" : "Tidak"}</b>
                      </Grid>
                      <Grid item xs={12}>
                        Jumlah : <b>{d.data["is_jamban"]}</b>
                      </Grid>
                    </Grid>
                  )}
                />
              </CardContent>
            </Card>
        </Grid>

        <Grid item xs={12} sm={6}>
            <Card>
              <CardHeader title="Jumlah Kepemilikan Septicktank per Kecamatan" />
              <CardContent className={classes.chartContainer}>
                <CustomNivoBar 
                  data={kecData.map((item) => {
                    item['Memiliki septicktank'] = item['is_septick'];
                    item['Tidak memiliki septicktank'] = item['is_not_septick'];
                    return item;
                  })}
                  keys={['Memiliki septicktank', 'Tidak memiliki septicktank']}
                  indexBy="kec"
                  tooltip={(d) => (
                    <Grid container>
                      <Grid item xs={12}>
                        Kecamatan : <b>{d.data["kec"]}</b>
                      </Grid>
                      <Grid item xs={12}>
                        Kepemilikan Jamban : <b>{d.id === "is_jamban" ? "Ya" : "Tidak"}</b>
                      </Grid>
                      <Grid item xs={12}>
                        Jumlah : <b>{d.data["is_jamban"]}</b>
                      </Grid>
                    </Grid>
                  )}
                />
              </CardContent>
            </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardHeader title="Tabel Rekapitulasi Pengelolaan Air Limbah Domestik" />
            <CardContent>
              <TableContainer style={{ maxHeight: 440 }}>
                <Table size="small" stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>Kecamatan</TableCell>
                      <TableCell>Desa</TableCell>
                      <TableCell>Jumlah Perekaman Data</TableCell>
                      <TableCell>Jumlah Kepemilikan Jamban</TableCell>
                      <TableCell>Jumlah Kepemilikan Septicktank</TableCell>
                      <TableCell>Jumlah Kemampuan Membangun Septicktank</TableCell>
                      <TableCell>Jumlah Kemauan Membangun Septicktank</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((item, key) => {
                      return (
                        <TableRow key={key.toString()}>
                          <TableCell>{item.kec}</TableCell>
                          <TableCell>{item.kel}</TableCell>
                          <TableCell>{item.jumlah_perekaman_data}</TableCell>
                          <TableCell>{item.is_jamban}</TableCell>
                          <TableCell>{item.is_septick}</TableCell>
                          <TableCell>{item.is_mampu_septick}</TableCell>
                          <TableCell>{item.is_mau_septick}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </CommonWrapper>
  );
};

export default DatasetView;

import "ol/ol.css";
import Map from "ol/Map";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import View from "ol/View";
import {Circle, Fill, Icon, Stroke, Style} from "ol/style";
import {LayerKeyValue} from "../../../variables";
import mapMarkerIcon from "../../../assets/map-marker-512.png";
import IconAnchorUnits from "ol/style/IconAnchorUnits";

export const map = new Map({
  target: undefined,
  layers: [
    new TileLayer({
      source: new OSM(),
    }),
  ],
  view: new View({
    projection: "EPSG:4326",
    center: [106.695596, -6.290138],
    zoom: 15,
  }),
});

export const layers: LayerKeyValue = {};

export const circleStyle = new Style({
  image: new Circle({
    radius: 10,
    fill: new Fill({
      color: [0, 153, 255, 1],
    }),
    stroke: new Stroke({
      color: [0, 113, 215, 1],
      width: 2,
    }),
  })
});

export const iconMarkerStyle = new Style({
  image: new Icon({
    src: mapMarkerIcon,
    scale: [0.1, 0.1],
    anchor: [0.5, 1],
    anchorXUnits: IconAnchorUnits.FRACTION,
    anchorYUnits: IconAnchorUnits.FRACTION,
  }),
});
import React from "react";
// import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";

import { formItemList } from "./variables";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faMapMarkedAlt } from "@fortawesome/free-solid-svg-icons";

import useStyles from "./styles";

import CommonWrapper from "../../../components/Pages/CommonWrapper";

const breadcrumbs = [
  {
    label: "Home",
    path: "/home",
  },
  {
    label: "Formulir Survei",
    path: "/survey",
  },
  {
    label: "Pengelolaan Air Limbah Domestik",
    path: "",
  },
];

const SurveyForm = () => {
  const classes = useStyles();
  return (
    <CommonWrapper path="/survey/form" title="Formulir Survei - Judul Survei" breadcrumbs={breadcrumbs}>
      <form>

        <Card>
          <Box p={3}>
          <Grid className={classes.root} container spacing={2}>
            {formItemList.map((item, key) => {
              let output;

              if (item.formType === "text" || item.formType === "number") {
                output = (
                  <Grid item xs={item.layoutWidth}>
                    <TextField type={item.formType} fullWidth label={item.label} name={item.name} variant="outlined" size="small" />
                  </Grid>
                );
              }

              if (item.formType === "photo") {
                output = (
                  <Grid item xs={item.layoutWidth}>
                    <Card>
                      <CardActionArea>
                        <CardHeader subheader="Ambil foto kepala keluarga" />
                        <CardMedia
                          className={classes.getImage}
                        >
                          <FontAwesomeIcon size="6x" icon={faCamera} />
                        </CardMedia>
                      </CardActionArea>
                    </Card>
                  </Grid>
                );
              }

              if (item.formType === "geolocation") {
                output = (
                  <Grid item xs={item.layoutWidth}>
                    <Card>
                      <CardActionArea>
                        <CardHeader subheader="Ambil titik koordinat rumah" />
                        <CardMedia
                          className={classes.getImage}
                        >
                          <FontAwesomeIcon size="6x" icon={faMapMarkedAlt} />
                        </CardMedia>
                      </CardActionArea>
                    </Card>
                  </Grid>
                );
              }

              if (item.formType === "radio") {
                output = (
                  <Grid item xs={item.layoutWidth}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">{item.label}</FormLabel>
                      <RadioGroup defaultValue="end" row>
                        {item.data.map((dataItem, dataKey) => {
                          return (
                            <FormControlLabel 
                              value={dataItem[0]}
                              control={<Radio color="primary" />}
                              label={dataItem[1]}
                            />
                          );
                        })}
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                );
              }
              return output;
            })}
          </Grid>
          </Box>
        </Card>
      </form>
    </CommonWrapper>
  );
};

export default SurveyForm;

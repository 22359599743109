
export const breadcrumbs=[
  {
    label: 'Home',
    path: '/home',
  },
  {
    label: 'Dataset',
    path: '/dataset',
  },
  {
    label: 'Pengelolaan Air Limbah Domestik',
    path: '',
  },
];


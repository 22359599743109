import React from "react";
// import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

import CommonWrapper from "../../components/Pages/CommonWrapper";
// import { MediaItem } from "../../types/Type";
import { mediaItemList } from "../Dashboard/variables";
import useStyles from "../Dashboard/styles";

const breadcrumbs = [
  {
    label: "Home",
    path: "/home",
  },
];

const Home = () => {
  const classes = useStyles();

  return (
    <CommonWrapper path="/home" title="Sistem Informasi Pengolahan Air Limbah Domestik (SIPALDO)" breadcrumbs={breadcrumbs}>
      <Grid className={classes.root} container spacing={2}>
        {mediaItemList.map((item, key) => {
          return (
            <Grid item xs={12} sm={4} key={key.toString()}>
              <Card>
                <CardActionArea>
                  <Link to={item.imagePath}>
                    <CardMedia
                      className={classes.media}
                      title={item.title}
                      style={{backgroundColor: item.backgroundColor}}
                    >
                      <FontAwesomeIcon size="6x" icon={item.icon} />
                    </CardMedia>
                  </Link>
                  <CardContent>
                    <Typography variant="h5" component="h2">
                      {item.title}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </CommonWrapper>
  );
};

export default Home;

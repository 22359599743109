import React, {createRef, useEffect, useRef, useState} from "react";
// import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import axios from "axios";

import CommonWrapper from "../../../components/Pages/CommonWrapper";
import {SurveiItem} from "./interfaces";
import {
  apiURL,
  bentukPenyedotanTangkiSeptikKeyValue,
  duaMingguTerakhirPAMTidakKeluarKeyValue,
  jarakTangkiSeptikKeSumurTerdekatKeyValue,
  jarakTempatTinggalTempatBABKeyValue,
  jenisBangunanKeyValue,
  jenisBuanganAirLimbahKeyValue,
  jenisJambanKeyValue,
  jenisKelaminKeyValue,
  keberadaanBangunanKeyValue,
  keberadaanManholePadaTangkiSeptikKeyValue,
  klasifikasiSPALDKeyValue,
  letakBuanganWCKeyValue,
  literPemakaianAirHarianKeyValue,
  masaSumurKekeringanKeyValue,
  pekerjaanKeyValue,
  pendidikanKeyValue,
  periodeDapatAirPAMKeyValue,
  saluranBuangBlackWaterKeyValue,
  saluranBuangGreyWaterKeyValue,
  standarTangkiSeptikKeyValue,
  statusDalamKeluargaKeyValue,
  statusKepemilikanBangunanKeyValue,
  sumberAirKekeringanSaatKemarauKeyValue,
  sumberAirMasakKeyValue,
  sumberAirMinumKeyValue,
  tempatBABKeyValue,
  tempatKeluargaBiasaMandiKeyValue,
  terakhirTangkiSeptikDisedotKeyValue,
  waktuTangkiSeptikDibuatKeyValue
} from "../../../variables";
import {useParams} from "react-router-dom";
import {CardContent, CardHeader, Table, TableBody, TableCell, TableContainer, TableRow} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import {iconMarkerStyle, layers, map} from "./map-functions";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import {Feature} from "ol";
import {Point} from "ol/geom";

const useStyles = makeStyles((theme) => ({
  root: {},
  mapContainer: {
    height: 400,
  },
}));

interface URLParams {
  id: string,
}

const PendataanDetail = () => {
  const classes = useStyles();
  const [surveiItem, setSurveiItem] = useState<SurveiItem>();
  const mapContainerRef = useRef<HTMLDivElement>(null);
  const mapPopupRef = createRef<HTMLDivElement>();
  const { id } = useParams<URLParams>();

  const breadcrumbs=[
    {
      label: 'Home',
      path: '/home',
    },
    {
      label: 'Rekapitulasi Pendataan',
      path: '/pendataan/overview',
    },
    {
      label: `Kelurahan ${surveiItem?.nama_kel}`,
      path: `/pendataan/list?kode_wilayah=${surveiItem?.kode_kel}`,
    },
    {
      label: `Detail Survei - ${surveiItem?.responden_nama}`,
      path: '',
    },
  ];

  const getDetailFromAPI = () => {
    axios.get(`${apiURL}/pendataan/survei/${id}`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((result) => {
      console.log(result);
      let item: SurveiItem = result.data["data"];
      setSurveiItem(item);
      initMap(item);
    }).catch((err) => {

    }).finally(() => {

    });
  }

  const initMap = (item: SurveiItem) => {
    if (mapContainerRef.current !== null) {
      console.log("map initialized !");
      map.setTarget(mapContainerRef.current);
      map.getView().setCenter([item.poi_x_longitude, item.poi_y_latitude]);
      layers['marker'] = new VectorLayer({
        source: new VectorSource({
          features: [new Feature({
            geometry: new Point([item.poi_x_longitude, item.poi_y_latitude]),
            name: 'marker',
          })]
        }),
        style: (feature) => {
          console.log("this is feature", feature);
          return iconMarkerStyle;
        }
      });
      map.addLayer(layers['marker']);
    }
  }

  useEffect(() => {
    getDetailFromAPI();
  }, []);

  return (
    <CommonWrapper path="/pendataan/list" title={`Detail Survei - ${surveiItem?.responden_nama}`} breadcrumbs={breadcrumbs}>
      <Grid className={classes.root} container spacing={2}>
        <Grid item xs={12} md={6}>
          <Grid direction={`column`} container spacing={2}>

            <Grid item>
              <Card>
                <CardHeader title={`Data Geolokasi`}/>
                <div ref={mapContainerRef} id="mapContainer" className={classes.mapContainer}></div>
                <CardContent>
                </CardContent>
              </Card>
            </Grid>

            <Grid item>
              <Card>
                <CardHeader title={`Data Responden`}/>
                <CardContent>
                  <TableContainer>
                    <Table size={`small`}>
                      <TableBody>
                        {surveiItem !== undefined && [
                          {title: "Nama", value: surveiItem.responden_nama},
                          {title: "NIK", value: surveiItem.responden_nik},
                          {title: "Jenis Kelamin", value: jenisKelaminKeyValue[surveiItem.responden_jenis_kelamin]},
                          {title: "Pekerjaan", value: pekerjaanKeyValue[surveiItem.responden_pekerjaan]},
                          {title: "Pendidikan Terakhir", value: pendidikanKeyValue[surveiItem.responden_pendidikan_terakhir]},
                          {title: "Status Dalam Keluarga", value: statusDalamKeluargaKeyValue[surveiItem.responden_status_dalam_keluarga]},
                        ].map((item, key) => {
                          return (
                            <TableRow key={key}>
                              <TableCell>{item.title}</TableCell>
                              <TableCell>{item.value}</TableCell>
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
              </Card>
            </Grid>

            <Grid item>
              <Card>
                <CardHeader title={`Data Bangunan`}/>
                <CardContent>
                  <TableContainer>
                    <Table size={`small`}>
                      <TableBody>
                        {surveiItem !== undefined && [
                          {title: "Jenis Bangunan", value: jenisBangunanKeyValue[surveiItem.responden_jenis_kelamin]},
                          {title: "Keberadaan Bangunan", value: keberadaanBangunanKeyValue[surveiItem.bangunan_keberadaan]},
                          {title: "Status Kepemilikan Bangunan", value: statusKepemilikanBangunanKeyValue[surveiItem.bangunan_status_kepemilikan]},
                          {title: "Luas Bangunan", value: <span>{surveiItem.bangunan_luas_m2} m<sup>2</sup></span>},
                          {title: "Luas Tanah", value: <span>{surveiItem.bangunan_luas_tanah_m2} m<sup>2</sup></span>},
                          {title: "Biaya Sewa Per Tahun", value: <span>Rp {surveiItem.bangunan_biaya_sewa_per_tahun}</span>},
                        ].map((item, key) => {
                          return (
                            <TableRow key={key}>
                              <TableCell>{item.title}</TableCell>
                              <TableCell>{item.value}</TableCell>
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
              </Card>
            </Grid>

            <Grid item>
              <Card>
                <CardHeader title={`Data Ekonomi`}/>
                <CardContent>
                  <TableContainer>
                    <Table size={`small`}>
                      <TableBody>
                        {surveiItem !== undefined && [
                          {title: "Jumlah pendapatan per bulan", value: surveiItem.ekonomi_jumlah_pendapatan_per_bulan},
                          {title: "Jumlah pengeluaran per bulan", value: surveiItem.ekonomi_jumlah_pengeluaran_per_bulan},
                        ].map((item, key) => {
                          return (
                            <TableRow key={key}>
                              <TableCell>{item.title}</TableCell>
                              <TableCell>{item.value}</TableCell>
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
              </Card>
            </Grid>

            <Grid item>
              <Card>
                <CardHeader title={`Data Air Bersih`}/>
                <CardContent>
                  <TableContainer>
                    <Table size={`small`}>
                      <TableBody>
                        {surveiItem !== undefined && [
                          {
                            title: "Sumber air minum",
                            value: surveiItem.air_sumber_air_minum_multi.length > 0 ?
                              <ul>{surveiItem.air_sumber_air_minum_multi.map((item, key) => (
                                <li>{sumberAirMinumKeyValue[item]}</li>
                              ))}</ul> : sumberAirMinumKeyValue[surveiItem.air_sumber_air_minum]
                          },
                          {
                            title: "Sumber air masak",
                            value: surveiItem.air_sumber_air_masak_multi.length > 0 ?
                              <ul>{surveiItem.air_sumber_air_masak_multi.map((item, key) => (
                                <li>{sumberAirMasakKeyValue[item]}</li>
                              ))}</ul> : sumberAirMasakKeyValue[surveiItem.air_sumber_air_masak]
                          },
                          {title: "Jumlah pemakaian air harian", value: `${literPemakaianAirHarianKeyValue[surveiItem.air_liter_pemakaian_air_harian]}`},
                          {title: "Periode mendapatkan air PAM", value: periodeDapatAirPAMKeyValue[surveiItem.air_periode_dapat_air_pam]},
                          {title: "Apakah air PAM tidak keluar dalam dua minggu terakhir", value: duaMingguTerakhirPAMTidakKeluarKeyValue[surveiItem.air_dua_minggu_terakhir_pam_tidak_keluar]},
                          {title: "Kualitas air PAM", value: periodeDapatAirPAMKeyValue[surveiItem.air_kualitas_air_pam]},
                          {title: "Kualitas air sumur", value: periodeDapatAirPAMKeyValue[surveiItem.air_kualitas_air_sumur]},
                          {title: "Sumber air pernah kekeringan ketika kemarau", value: sumberAirKekeringanSaatKemarauKeyValue[surveiItem.air_kemarau_sumber_air_pernah_kekeringan]},
                          {title: "Berapa lama sumur mengalami kekeringan", value: masaSumurKekeringanKeyValue[surveiItem.air_masa_sumur_kekeringan]},
                        ].map((item, key) => {
                          return (
                            <TableRow key={key}>
                              <TableCell>{item.title}</TableCell>
                              <TableCell>{item.value}</TableCell>
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
              </Card>
            </Grid>

            <Grid item>
              <Card>
                <CardHeader title={`Data Air Limbah`}/>
                <CardContent>
                  <TableContainer>
                    <Table size={`small`}>
                      <TableBody>
                        {surveiItem !== undefined && [
                          {
                            title: "Tempat keluarga biasa mandi",
                            value: surveiItem.limbah_tempat_keluarga_biasa_mandi_multi.length > 0 ?
                              <ul>{surveiItem.limbah_tempat_keluarga_biasa_mandi_multi.map((item, key) => (
                              <li>{tempatKeluargaBiasaMandiKeyValue[item]}</li>
                            ))}</ul> : tempatKeluargaBiasaMandiKeyValue[surveiItem.limbah_tempat_keluarga_biasa_mandi]
                          },
                          {
                            title: "Jenis buangan air limbah",
                            value: surveiItem.limbah_jenis_buangan_air_limbah_multi.length > 0 ?
                              <ul>{surveiItem.limbah_jenis_buangan_air_limbah_multi.map((item, key) => (
                                <li>{jenisBuanganAirLimbahKeyValue[item]}</li>
                              ))}</ul> : jenisBuanganAirLimbahKeyValue[surveiItem.limbah_jenis_buangan_air_limbah]
                          },
                          {
                            title: "Saluran buang grey water",
                            value: surveiItem.limbah_saluran_buang_grey_water_multi.length > 0 ?
                              <ul>{surveiItem.limbah_saluran_buang_grey_water_multi.map((item, key) => (
                                <li>{saluranBuangGreyWaterKeyValue[item]}</li>
                            ))}</ul> : saluranBuangGreyWaterKeyValue[surveiItem.limbah_saluran_buang_grey_water]
                          },
                          {
                            title: "Tempat BAB",
                            value: surveiItem.limbah_tempat_bab_multi.length > 0 ?
                              <ul>{surveiItem.limbah_tempat_bab_multi.map((item, key) => (
                                <li>{tempatBABKeyValue[item]}</li>
                              ))}</ul> : tempatBABKeyValue[surveiItem.limbah_tempat_bab]
                          },
                          {
                            title: "Jenis jamban",
                            value: jenisJambanKeyValue[surveiItem.limbah_jenis_jamban],
                          },
                          {
                            title: "Saluran buang blak water",
                            value: surveiItem.limbah_saluran_buang_black_water_multi.length > 0 ?
                              <ul>{surveiItem.limbah_saluran_buang_black_water_multi.map((item, key) => (
                                <li>{saluranBuangBlackWaterKeyValue[item]}</li>
                              ))}</ul> : saluranBuangBlackWaterKeyValue[surveiItem.limbah_saluran_buang_black_water]
                          },
                          {
                            title: "Klasifikasi SPALD",
                            value: surveiItem.limbah_klasifikasi_spald_multi.length > 0 ?
                              <ul>{surveiItem.limbah_klasifikasi_spald_multi.map((item, key) => (
                                <li>{klasifikasiSPALDKeyValue[item]}</li>
                              ))}</ul> : klasifikasiSPALDKeyValue[surveiItem.limbah_klasifikasi_spald]
                          },
                          {
                            title: "Standar tangki septik",
                            value: standarTangkiSeptikKeyValue[surveiItem.limbah_standar_tangki_septik],
                          },
                          {
                            title: "Letak buangan WC",
                            value: letakBuanganWCKeyValue[surveiItem.limbah_letak_buangan_wc],
                          },
                          {
                            title: "Waktu tangki septik dibuat",
                            value: waktuTangkiSeptikDibuatKeyValue[surveiItem.limbah_waktu_tangki_septik_dibuat],
                          },
                          {
                            title: "Terakhir tangki septik disedot",
                            value: terakhirTangkiSeptikDisedotKeyValue[surveiItem.limbah_terakhir_tangki_septik_disedot],
                          },
                          {
                            title: "Keberadaan manhole pada tangki septik",
                            value: keberadaanManholePadaTangkiSeptikKeyValue[surveiItem.limbah_keberadaan_manhole_pada_tangki_septik],
                          },
                          {
                            title: "Keberadaan manhole pada tangki septik",
                            value: keberadaanManholePadaTangkiSeptikKeyValue[surveiItem.limbah_keberadaan_manhole_pada_tangki_septik],
                          },
                          {
                            title: "Bentuk penyedotan tangki septik",
                            value: surveiItem.limbah_bentuk_penyedotan_tangki_septik_multi.length > 0 ?
                              <ul>{surveiItem.limbah_bentuk_penyedotan_tangki_septik_multi.map((item, key) => (
                                <li>{bentukPenyedotanTangkiSeptikKeyValue[item]}</li>
                              ))}</ul> : bentukPenyedotanTangkiSeptikKeyValue[surveiItem.limbah_bentuk_penyedotan_tangki_septik]
                          },
                          {
                            title: "Jarak tangki septik ke sumur terdekat",
                            value: jarakTangkiSeptikKeSumurTerdekatKeyValue[surveiItem.limbah_jarak_tangki_septik_ke_sumur_terdekat],
                          },
                          {
                            title: "Jarak tempat tinggal ke tempat BAB",
                            value: jarakTempatTinggalTempatBABKeyValue[surveiItem.limbah_jarak_tempat_tinggal_ke_tempat_bab],
                          },
                        ].map((item, key) => {
                          return (
                            <TableRow key={key}>
                              <TableCell>{item.title}</TableCell>
                              <TableCell>{item.value}</TableCell>
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

        </Grid>

        <Grid item xs={12} md={6}>
          <Grid container direction={`row`} spacing={2}>
            { surveiItem?.foto_spald !== null && surveiItem !== undefined &&
              <Grid item xs={6}>
                <Card>
                  <CardHeader title={`Foto SPALD`}/>
                  <CardContent>
                    <img width="100%" alt={`Foto SPALD`} src={`/media/pendataan/${surveiItem.id}/${surveiItem.foto_spald}`} />
                  </CardContent>
                </Card>
              </Grid>
            }

            { surveiItem?.foto_bangunan !== null && surveiItem !== undefined &&
            <Grid item xs={6}>
              <Card>
                <CardHeader title={`Foto Bangunan`}/>
                <CardContent>
                  <img width="100%" alt={`Foto Bangunan`} src={`/media/pendataan/${surveiItem.id}/${surveiItem.foto_bangunan}`} />
                </CardContent>
              </Card>
            </Grid>
            }

            { surveiItem !== undefined &&
            <Grid item xs={6}>
              <Card>
                <CardHeader title={`Foto Responden`}/>
                <CardContent>
                  <img width="100%" alt={`Foto Responden`} src={`/media/pendataan/${surveiItem.id}/${surveiItem.foto_responden}`} />
                </CardContent>
              </Card>
            </Grid>
            }

            { surveiItem !== undefined &&
            <Grid item xs={6}>
              <Card>
                <CardHeader title={`Foto Kartu Keluarga`}/>
                <CardContent>
                  <img width="100%" alt={`Foto Kartu Keluarga`} src={`/media/pendataan/${surveiItem.id}/${surveiItem.foto_kartu_keluarga}`} />
                </CardContent>
              </Card>
            </Grid>
            }

          </Grid>
        </Grid>
      </Grid>
    </CommonWrapper>
  );
}

export default PendataanDetail;

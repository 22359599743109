export const themeColors = [
  '#f6bb00', 
  '#00aa90',
  '#007d3c',
  '#0094bb',
  '#da251d',
  '#551d35',
  '#6be3d1',
  '#005245',
];

const style = {
  color: {
    main: "#0094bb",
    dark: "#006d8a",
    text: "rgb(41, 41, 41)",
    border: {
      light: "rgba(41, 41, 41, 0.1)",
    },
  },
  boxShadow: {
    elevation1: `0 0 13px 0 rgba(58, 96, 106, 0.13)`,
    elevation4: `0 0 18px 0 rgba(58, 96, 106, 0.55)`,
  },
};

export default style;

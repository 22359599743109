import "ol/ol.css";
import GeoJSON from "ol/format/GeoJSON";
import Map from "ol/Map";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import View from "ol/View";
import Select from "ol/interaction/Select";
import { pointerMove } from "ol/events/condition";
import { Fill, Stroke, Style } from "ol/style";
import Overlay from "ol/Overlay.js";
import axios from "axios";

import { themeColors } from "../../../styles";
import XYZ from "ol/source/XYZ";

// https://openlayers.org/en/latest/examples/geojson.html

type GeoJSONFeatureCollection = {
  features: {}[];
};

const commonStroke = new Stroke({
  color: "#ffffffff",
  width: 2,
});

const styles = [
  new Style({
    stroke: commonStroke,
    fill: new Fill({
      color: `${themeColors[6]}dd`,
    }),
  }),
  new Style({
    stroke: commonStroke,
    fill: new Fill({
      color: `${themeColors[1]}dd`,
    }),
  }),
  new Style({
    stroke: commonStroke,
    fill: new Fill({
      color: `${themeColors[7]}dd`,
    }),
  }),
  new Style({
    stroke: commonStroke,
    fill: new Fill({
      color: `${themeColors[0]}dd`,
    }),
  }),
];

const popupOverlay = new Overlay({
  id: 'popupOverlay',
  element: undefined,
});

export const map = new Map({
  target: undefined,
  layers: [
    new TileLayer({ // Satellite map
      visible: false,
      source: new XYZ({
        url: 'http://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}'
      })
    }),
    new TileLayer({ // Elevation map
      visible: false,
      source: new XYZ({
        url: 'http://mt0.google.com/vt/lyrs=p&hl=en&x={x}&y={y}&z={z}'
      })
    }),
    new TileLayer({
      visible: false,
      source: new OSM(),
    }),
    // new TileLayer({ // Open Topo Map
    //   visible: false,
    //   source: new XYZ({
    //     url: 'https://{a-c}.tile.opentopomap.org/{z}/{x}/{y}.png'
    //   })
    // }),
  ],
  view: new View({
    projection: "EPSG:4326",
    center: [106.695596, -6.290138],
    zoom: 12,
  }),
});

type Agregasi = {
  style_id: number;
  jumlah_perekaman_data: number;
  is_jamban: number;
  is_septick: number;
};

export const initMap = (
  map: Map,
  desaWithStyle: { [key: string]: Agregasi },
  layerKV: { [key: string]: VectorLayer },
  setLayerKeyList: (arg: { key: string; label: string }[]) => void,
  refs: { [key: string]: React.RefObject<HTMLDivElement> },
  basemap: number,
) => {

  map.getLayers().forEach((baseLayer, baseLayerNumber) => {
    console.log(baseLayerNumber, map.getLayers().getLength())
    if(baseLayerNumber === basemap) {
      baseLayer.setVisible(true)
    } else {
      if(baseLayerNumber < map.getLayers().getLength() - 1) {
        baseLayer.setVisible(false)
      }
    }
  })

  if (refs.mapContainer !== undefined && refs.mapContainer.current !== null) {
    map.setTarget(refs.mapContainer.current);
  }

  if (refs.mapPopup !== undefined && refs.mapPopup.current !== null) {
    popupOverlay.setElement(refs.mapPopup.current);
    map.addOverlay(popupOverlay);
  }

  const select = new Select({
    condition: pointerMove,
  });

  select.on("select", (e) => {
    if (e.selected.length > 0) {
      const selectedFeature = e.selected[0];
      selectedFeature.setStyle(styles[3]);
      popupOverlay.setPosition(e.mapBrowserEvent.coordinate);

      const props = selectedFeature.getProperties();
      const elem = popupOverlay.getElement();

      const data = desaWithStyle[parseInt(props['IDDESA'])];

      // console.log(data);

      let jumlah_perekaman_data  = '-';
      let jumlah_tidak_ada_jamban = '-';
      let jumlah_tidak_ada_septicktank = '-';
      if(data !== undefined && data.jumlah_perekaman_data !== undefined) {
        jumlah_perekaman_data = data.jumlah_perekaman_data.toString();

        if(data.is_jamban !== undefined) {
          jumlah_tidak_ada_jamban = (data.jumlah_perekaman_data - data.is_jamban).toString();
        }

        if(data.is_septick !== undefined) {
          jumlah_tidak_ada_septicktank = (data.jumlah_perekaman_data - data.is_septick).toString();
        }
      }


      if(elem !== undefined) {
        elem.innerHTML = `Kecamatan <b>${props['KECAMATAN']}</b>`;
        elem.innerHTML += `<br>Kelurahan <b>${props['DESA']}</b>`;
        elem.innerHTML += `<br>Jumlah Perekaman Data <b>${jumlah_perekaman_data}</b>`;
        elem.innerHTML += `<br>Jumlah Tidak Ada Jamban <b>${jumlah_tidak_ada_jamban}</b>`;
        elem.innerHTML += `<br>Jumlah Tidak Ada Septicktank <b>${jumlah_tidak_ada_septicktank}</b>`;
      }
      //console.log(e.mapBrowserEvent.coordinate, props);
    } else {
      popupOverlay.setPosition(undefined);
    }
  });
  map.addInteraction(select);

  /*
  map.on('pointermove', (e) => {
    map.forEachFeatureAtPixel(e.pixel, (f, l) => {
      l.getFeatures(e.pixel).then((f) => {
        if(f.length > 0) {
          f[0].setStyle(styles[2]);
        }
      })
    })
  });
   * */

  getBoundaryMapGeoJSON(map, desaWithStyle, layerKV, setLayerKeyList, refs);
};

const getBoundaryMapGeoJSON = (map: Map, desaWithStyle: { [key: string]: Agregasi }, layerKV: { [key: string]: VectorLayer }, setLayerKeyList: (arg: { key: string; label: string }[]) => void, refs: { [key: string]: React.RefObject<HTMLDivElement> }) => {
  const boundaryMapItemName = "tangselGeoJSONMap";
  if (localStorage.getItem(boundaryMapItemName) === null) {
    axios
      .get("/media/documents/shp-tangerang-selatan.json", {})
      .then((result) => {
        const featureCollection = result.data;
        localStorage.setItem(boundaryMapItemName, JSON.stringify(featureCollection));
        addBoundaryMapVectorLayer(featureCollection, map, desaWithStyle, layerKV, setLayerKeyList, refs);
        // set feature collection to map
      })
      .catch(() => {});
  } else {
    const featureCollectionString = localStorage.getItem(boundaryMapItemName);
    if (typeof featureCollectionString === "string") {
      const featureCollection = JSON.parse(featureCollectionString);
      addBoundaryMapVectorLayer(featureCollection, map, desaWithStyle, layerKV, setLayerKeyList, refs);
    }
    // set feature collection to map
  }
};

export const addBoundaryMapVectorLayer = (geoJSON: GeoJSONFeatureCollection, map: Map, desaWithStyle: { [key: string]: Agregasi }, layerKV: { [key: string]: VectorLayer }, setLayerKeyList: (arg: { key: string; label: string }[]) => void, refs: { [key: string]: React.RefObject<HTMLDivElement> }) => {
  let countBoundaryMap = 0;

  map
    .getLayers()
    .getArray()
    .map((item, key) => {
      if (item.getClassName() === "boundaryMap") {
        countBoundaryMap += 1;
      }
      return true;
    });

  if (countBoundaryMap === 0) {
    const vectorSource = new VectorSource({
      features: new GeoJSON().readFeatures(geoJSON),
    });

    setLayerKeyList([
      {
        key: "boundaryMap",
        label: "Peta Batas Wilayah",
      },
    ]);
    layerKV["boundaryMap"] = new VectorLayer({
      className: "boundaryMap",
      source: vectorSource,
    });

    layerKV["boundaryMap"].setStyle(styles[0]);
    // console.log(Object.keys(desaWithStyle));

    layerKV["boundaryMap"].setStyle((feature, resolution) => {
      const props = feature.getProperties();
      const desaID: string = props.IDDESA;

      let styleKey = 0;

      //console.log("ini props", desaWithStyle[desaID]);
      try {
        if (desaWithStyle[desaID] === undefined) {
        } else {
          styleKey = desaWithStyle[desaID].style_id;
        }
      } catch (err) {
        // console.log(err, desaID);
      }

      return styles[styleKey];
    });

    map.addLayer(layerKV["boundaryMap"]);
  }
};

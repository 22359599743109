import React from "react";
import { ResponsiveBar } from "@nivo/bar";

import { themeColors } from "../../../styles";
import { CustomTooltipProps } from "./types";
import { GlobalObject } from "../../../types/Type";

type Props = {
  data: GlobalObject[];
  keys: string[];
  indexBy: string;
  tooltip: (arg: CustomTooltipProps) => JSX.Element;
};

const CustomNivoBar = (props: Props) => {
  const { data, keys, indexBy, tooltip } = props;

  return (
    <ResponsiveBar
      data={data}
      keys={keys}
      indexBy={indexBy}
      colors={themeColors}
      margin={{ top: 10, right: 30, bottom: 40, left: 30 }}
      padding={0.3}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        legend: "Kecamatan",
        legendPosition: "middle",
        legendOffset: 32,
      }}
      legends={[
        {
          dataFrom: "keys",
          anchor: "top-left",
          direction: "column",
          justify: false,
          translateX: 20,
          translateY: 0,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: "left-to-right",
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: "hover",
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      animate={true}
      motionStiffness={90}
      motionDamping={15}
      enableGridY={false}
      tooltip={tooltip}
    />
  );
};

export default CustomNivoBar;

import React, {useEffect, useState} from "react";
// import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";

import CommonWrapper from "../../../components/Pages/CommonWrapper";
import {
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import axios from "axios";
import {Link} from "react-router-dom";
import {apiURL, kecamatanKeyValue} from "../../../variables";

const breadcrumbs = [
  {
    label: 'Home',
    path: '/home',
  },
  {
    label: 'Rekapitulasi Pendataan',
    path: '',
  },
];

const useStyles = makeStyles((theme) => ({
  root: {},
}));

interface ITableDataItem {
  kode_wilayah: string,
  nama_wilayah: string,
  values: number[],
}

interface ITableData {
  title: string | undefined,
  headers: string[] | undefined,
  items: ITableDataItem[],
}

const PendataanOverview = () => {
  const classes = useStyles();

  const [tableData, setTableData] = useState<ITableData>({
    title: undefined,
    headers: undefined,
    items: [],
  });

  const getListFromAPI = () => {
    //console.log(userDetails);
    axios.get(`${apiURL}/pendataan/survei-overview?kode_wilayah=3674&surveyor_id=1`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`,
      },
    }).then((result) => {
      let data = result.data["data"];
      setTableData(data);
    }).catch((err) => {

    }).finally(() => {

    });
  }

  // Component did mount
  useEffect(() => {
    getListFromAPI();
  }, []);

  return (
    <CommonWrapper path="/pendataan/overview" title="Rekapitulasi Pendataan" breadcrumbs={breadcrumbs}>
      <Grid className={classes.root} container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title={`Rekapitulasi Pendataan`} />
            <CardContent>
              <TableContainer>
                <Table size="small" stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>Kecamatan</TableCell>
                      <TableCell>Kelurahan</TableCell>
                      <TableCell>Jumlah Pendataan</TableCell>
                      <TableCell>Jumlah Permohonan Bantuan</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableData.items.length > 0 &&
                    tableData.items.map((item, key) => {
                      return (
                        <TableRow key={key}>
                          <TableCell>{kecamatanKeyValue[item.kode_wilayah.substring(0, 7)]}</TableCell>
                          <TableCell><Link to={`/pendataan/list?kode_wilayah=${item.kode_wilayah}`}>{item.nama_wilayah}</Link></TableCell>
                          <TableCell>{item.values[0]}</TableCell>
                          <TableCell>{item.values[1]}</TableCell>
                        </TableRow>
                      )
                    })
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </CommonWrapper>
  );
};

export default PendataanOverview;

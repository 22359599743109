import React from "react";
// import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

import CommonWrapper from "../../../components/Pages/CommonWrapper";

const breadcrumbs=[
  {
    label: 'Home',
    path: '/home',
  },
  {
    label: 'Profil',
    path: '',
  },
];

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const UserProfile = () => {
  const classes = useStyles();
  return (
    <CommonWrapper path="/user/profile" title="Ubah Profil" breadcrumbs={breadcrumbs}>
      <Grid className={classes.root}>
      </Grid>
    </CommonWrapper>
  );
};

export default UserProfile;

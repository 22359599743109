import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  getImage: {
    height: 180,
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#cccccc',
    "& svg": {
      color: 'rgba(255, 255, 255, 0.7)',
      width: '180px',
      height: '180px',
    }
  },
}));

export default useStyles;

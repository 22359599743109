import Map from "ol/Map";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import View from "ol/View";
import {LayerKeyValue} from "../../../variables";
import {Fill, Icon, Stroke, Style} from "ol/style";
import mapMarkerIcon from "../../../assets/map-marker-512.png";
import IconAnchorUnits from "ol/style/IconAnchorUnits";
import XYZ from "ol/source/XYZ";

export const map = new Map({
  target: undefined,
  layers: [
    new TileLayer({ // Satellite map
      visible: false,
      source: new XYZ({
        url: 'http://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}'
      })
    }),
    new TileLayer({ // Elevation map
      visible: false,
      source: new XYZ({
        url: 'http://mt0.google.com/vt/lyrs=p&hl=en&x={x}&y={y}&z={z}'
      })
    }),
    new TileLayer({
      visible: false,
      source: new OSM(),
    }),
  ],
  view: new View({
    projection: "EPSG:4326",
    center: [106.695596, -6.290138],
    zoom: 12,
  }),
});

export const layers: LayerKeyValue = {};

export const iconMarkerStyle = new Style({
  image: new Icon({
    src: mapMarkerIcon,
    scale: [0.1, 0.1],
    anchor: [0.5, 1],
    anchorXUnits: IconAnchorUnits.FRACTION,
    anchorYUnits: IconAnchorUnits.FRACTION,
  }),
});

export const radiusCircleStyle = new Style({
  fill: new Fill({color: [255, 84, 84, 0]}),
  stroke: new Stroke({color: [255, 84, 84, 1], width: 2}),
});

type FormItem = {
  name: string,
  label: string,
  defaultValue: string | number,
  formType: string,
  data: string[][], 
  layoutWidth: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12,
}

export const formItemList: FormItem[] = [
  {
    name: 'kode_kecamatan',
    label: 'Kecamatan',
    defaultValue: '0',
    formType: 'text',
    data: [
      ['1', 'Kecamatan 2'],
    ],
    layoutWidth: 6,
  },
  {
    name: 'kode_desa',
    label: 'Kelurahan',
    defaultValue: '0',
    formType: 'text',
    data: [
      ['1', 'Kelurahan 2'],
    ],
    layoutWidth: 6,
  },
  {
    name: 'nama_kk',
    label: 'Nama kepala keluarga',
    defaultValue: '',
    formType: 'text',
    data: [[]],
    layoutWidth: 12,
  },
  {
    name: 'jumlah_anggota_kk',
    label: 'Jumlah anggota keluarga',
    defaultValue: '',
    formType: 'number',
    data: [[]],
    layoutWidth: 12,
  },
  {
    name: 'foto_kk',
    label: 'Foto kepala keluarga',
    defaultValue: '',
    formType: 'photo',
    data: [[]],
    layoutWidth: 6,
  },
  {
    name: 'koordinat_rumah',
    label: 'Titik Koordinat Rumah',
    defaultValue: '',
    formType: 'geolocation',
    data: [[]],
    layoutWidth: 6,
  },
  {
    name: 'alamat',
    label: 'Alamat',
    defaultValue: '',
    formType: 'text',
    data: [[]],
    layoutWidth: 12,
  },
  {
    name: 'rt',
    label: 'RT',
    defaultValue: '',
    formType: 'text',
    data: [[]],
    layoutWidth: 6,
  },
  {
    name: 'rw',
    label: 'RW',
    defaultValue: '',
    formType: 'text',
    data: [[]],
    layoutWidth: 6,
  },
  {
    name: 'status_jamban',
    label: 'Kepemilikan jamban',
    defaultValue: '',
    formType: 'radio',
    data: [['1', 'Ya'], ['2', 'Tidak']],
    layoutWidth: 4,
  },
  {
    name: 'status_septicktank',
    label: 'Kepemilikan septicktank',
    defaultValue: '',
    formType: 'radio',
    data: [['1', 'Ya'], ['2', 'Tidak']],
    layoutWidth: 4,
  },
  {
    name: 'kemauan_membuat_septicktank',
    label: 'Kemauan membuat septicktank',
    defaultValue: '',
    formType: 'radio',
    data: [['1', 'Ya'], ['2', 'Tidak']],
    layoutWidth: 4,
  },
  {
    name: 'alasan_belum_septicktank',
    label: 'Alasan belum membuat septicktank',
    defaultValue: '',
    formType: 'text',
    data: [[]],
    layoutWidth: 12,
  },
  {
    name: 'dokumen',
    label: 'Upload dokumen pendukung',
    defaultValue: '',
    formType: 'file',
    data: [[]],
    layoutWidth: 12,
  },
];

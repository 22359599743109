// export const apiURL = 'https://sipaldo.negeriku.id/api/v1';
// export const apiURL = '/api/v1';
import VectorLayer from "ol/layer/Vector";

export const apiURL = "/api/v1";

export interface KeyValue {
  [key: string]: string;
}

export type LayerKeyValue = {
  [key: string]: VectorLayer;
};

interface IntKeyValue {
  [key: number]: string;
}

export const jenisKelaminKeyValue: KeyValue = {
  "0": "-",
  "1": "Pria",
  "2": "Wanita",
};

export const statusDalamKeluargaKeyValue: KeyValue = {
  "0": "-",
  "1": "Suami/Ayah",
  "2": "Istri/Ibu",
  "3": "Anak",
  "4": "Lainnya",
};

export const pekerjaanKeyValue: KeyValue = {
  "0": "-",
  "1": "Guru",
  "2": "PNS",
  "3": "TNI/POLRI",
  "4": "Wirausaha",
  "5": "Buruh",
  "6": "Karyawan",
  "7": "Lainnya",
};

export const pendidikanKeyValue: KeyValue = {
  "0": "-",
  "1": "Tidak Sekolah",
  "2": "SD",
  "3": "SMP",
  "4": "SMA/SMK",
  "5": "D1/D2",
  "6": "D3",
  "7": "D4/S1",
  "8": "S2",
  "9": "S3",
};

export const jumlahPendapatanKeyValue: KeyValue = {
  "0": "-",
  "1": "< Rp 1.000.000",
  "2": "Rp 1.000.000 s/d Rp 2.000.000",
  "3": "Rp 2.000.000 s/d Rp 4.000.000",
  "4": "Rp 4.000.000 s/d Rp 6.000.000",
  "5": "Rp 6.000.000 s/d Rp 8.000.000",
  "6": "Rp 8.000.000 s/d Rp 10.000.000",
  "7": "Rp 10.000.000 s/d Rp 12.000.000",
  "8": "Rp 12.000.000 s/d Rp 14.000.000",
  "9": "Rp 14.000.000 s/d Rp 16.000.000",
  "10": "> Rp 16.000.000",
};

export const jumlahPengeluaranKeyValue: KeyValue = {
  "0": "-",
  "1": "< Rp 1.000.000",
  "2": "Rp 1.000.000 s/d Rp 2.000.000",
  "3": "Rp 2.000.000 s/d Rp 4.000.000",
  "4": "Rp 4.000.000 s/d Rp 6.000.000",
  "5": "Rp 6.000.000 s/d Rp 8.000.000",
  "6": "Rp 8.000.000 s/d Rp 10.000.000",
  "7": "Rp 10.000.000 s/d Rp 12.000.000",
  "8": "Rp 12.000.000 s/d Rp 14.000.000",
  "9": "Rp 14.000.000 s/d Rp 16.000.000",
  "10": "> Rp 16.000.000",
};

export const kecamatanKeyValue: KeyValue = {
  "3674010": "SETU",
  "3674020": "SERPONG",
  "3674030": "PAMULANG",
  "3674040": "CIPUTAT",
  "3674050": "CIPUTAT TIMUR",
  "3674060": "PONDOK AREN",
  "3674070": "SERPONG UTARA",
};

export const kelurahanKeyValue: KeyValue = {
  "3674010001": "KRANGGAN",
  "3674010002": "MUNCUL",
  "3674010003": "KADEMANGAN",
  "3674010004": "SETU",
  "3674010005": "BABAKAN",
  "3674010006": "BAKTI JAYA",
  "3674020009": "BUARAN",
  "3674020010": "CIATER",
  "3674020011": "RAWA MEKAR JAYA",
  "3674020012": "RAWA BUNTU",
  "3674020013": "SERPONG",
  "3674020018": "CILENGGANG",
  "3674020019": "LENGKONG GUDANG",
  "3674020020": "LENGKONG GUDANG TIMUR",
  "3674020021": "LENGKONG WETAN",
  "3674030001": "PONDOK BENDA",
  "3674030002": "PAMULANG BARAT",
  "3674030003": "PAMULANG TIMUR",
  "3674030004": "PONDOK CABE UDIK",
  "3674030005": "PONDOK CABE ILIR",
  "3674030006": "KEDAUNG",
  "3674030007": "BAMBU APUS",
  "3674030008": "BENDA BARU",
  "3674040001": "SARUA",
  "3674040002": "JOMBANG",
  "3674040003": "SAWAH BARU",
  "3674040004": "SARUA INDAH",
  "3674040005": "SAWAH",
  "3674040006": "CIPUTAT",
  "3674040007": "CIPAYUNG",
  "3674050001": "PISANGAN",
  "3674050002": "CIREUNDEU",
  "3674050003": "CEMPAKA PUTIH",
  "3674050004": "REMPOA",
  "3674050005": "RENGAS",
  "3674050006": "PONDOK RANJI",
  "3674060001": "PERIGI BARU",
  "3674060002": "PONDOK KACANG BARAT",
  "3674060003": "PONDOK KACANG TIMUR",
  "3674060004": "PERIGI",
  "3674060005": "PONDOK PUCUNG",
  "3674060006": "PONDOK JAYA",
  "3674060007": "PONDOK AREN",
  "3674060008": "JURANG MANGGU BARAT",
  "3674060009": "JURANG MANGGU TIMUR",
  "3674060010": "PONDOK KARYA",
  "3674060011": "PONDOK BETUNG",
  "3674070001": "LENGKONG KARYA",
  "3674070002": "JELUPANG",
  "3674070003": "PONDOK JAGUNG",
  "3674070004": "PONDOK JAGUNG TIMUR",
  "3674070005": "PAKULONAN",
  "3674070006": "PAKU ALAM",
  "3674070007": "PAKU JAYA",
};

export const pemanfaatanBangunanKeyValue: KeyValue = {
  "0": "-",
  "1": "Hunian",
  "2": "Keagamaan",
  "3": "Usaha / Industri",
  "4": "Pendidikan Kesehatan Sosial Budaya",
  "5": "Khusus (militer, nuklir, istana, dsb.)",
  "6": "Campuran",
};

export const jenisBangunanKeyValue: KeyValue = {
  "0": "-",
  "1": "Permanen",
  "2": "Semi Permanen",
  "3": "Darurat",
};

export const keberadaanBangunanKeyValue: KeyValue = {
  "0": "-",
  "1": "Baru",
  "2": "Eksisting",
};

export const statusKepemilikanBangunanKeyValue: KeyValue = {
  "0": "-",
  "1": "SHM atau HGB",
  "2": "Sewa/Kontrak",
  "3": "Menumpang",
  "4": "Rumah Sendiri di Atas Tanah Orang Lain",
  "5": "Aset Pemerintah",
};

export const sumberAirMinumKeyValue: KeyValue = {
  "0": "-",
  "1": "Ledeng/PAM sendiri",
  "2": "Ledeng/PAM rumah tetangga",
  "3": "Ledeng/hidran umum",
  "4": "Sumur sendiri",
  "5": "Sumur tetangga/umum",
  "6": "Air kemasan/galon",
  "7": "Penjual air keliling",
  "8": "Air permukaan (sungai, danau, dsb.)",
  "9": "Air hujan ditampung",
  "10": "Lainnya",
};

export const sumberAirMasakKeyValue: KeyValue = {
  "0": "-",
  "1": "Ledeng/PAM sendiri",
  "2": "Ledeng/PAM rumah tetangga",
  "3": "Ledeng/hidran umum",
  "4": "Sumur sendiri",
  "5": "Sumur tetangga/umum",
  "6": "Air kemasan/galon",
  "7": "Penjual air keliling",
  "8": "Air permukaan (sungai, danau, dsb.)",
  "9": "Air hujan ditampung",
  "10": "Lainnya",
};

export const periodeDapatAirPAMKeyValue: KeyValue = {
  "0": "-",
  "1": "Biasanya setiap hari",
  "2": "2-4 kali seminggu",
  "3": "1 kali seminggu",
  "4": "Tidak tentu / tidak tahu",
};

export const duaMingguTerakhirPAMTidakKeluarKeyValue: KeyValue = {
  "0": "-",
  "1": "Pernah",
  "2": "Tidak pernah",
  "3": "Tidak tahu",
};

export const kualitasAirPAMKeyValue: KeyValue = {
  "0": "-",
  "1": "Baik / tidak ada masalah",
  "2": "Ada masalah bau / rasa / berwarna",
};

export const kualitasAirSumurKeyValue: KeyValue = {
  "0": "-",
  "1": "Baik / tidak ada masalah",
  "2": "Ada masalah bau / rasa / berwarna",
};

export const sumberAirKekeringanSaatKemarauKeyValue: KeyValue = {
  "0": "-",
  "1": "Pernah",
  "2": "Tidak pernah",
  "3": "Tidak tahu",
};

export const masaSumurKekeringanKeyValue: KeyValue = {
  "0": "-",
  "1": "2 minggu s/d 1 bulan",
  "2": "Lebih dari 1 bulan s/d 3 bulan",
  "3": "Lebih dari 3 bulan",
};

export const literPemakaianAirHarianKeyValue: KeyValue = {
  "0": "-",
  "1": "< 80 lt",
  "2": "80 - 120 lt",
  "3": "120 - 160 lt",
  "4": "> 160 lt",
};

export const tempatKeluargaBiasaMandiKeyValue: KeyValue = {
  "0": "-",
  "1": "Kamar mandi sendiri",
  "2": "Kamar mandi tetangga / saudara",
  "3": "MCK umum",
  "4": "Danau / sungai",
  "5": "Lainnya",
};

export const jenisBuanganAirLimbahKeyValue: KeyValue = {
  "0": "-",
  "1": "Tinja",
  "2": "Air limbah domestik / rumah tangga (termasuk air bekas mandi dan cuci)",
  "3": "Air limbah B3",
};

export const saluranBuangGreyWaterKeyValue: KeyValue = {
  "0": "-",
  "1": "Saluran terbuka ke riool / selokan / sungai",
  "2": "Saluran terbuka ke kolam / rawa / genangan",
  "3": "Saluran terbuka ke cubluk",
  "4": "Saluran terbuka ke tangki septik",
  "5": "Saluran tertutup ke riool / selokan / sungai",
  "6": "Saluran tertutup ke kolam / rawa / genangan",
  "7": "Saluran tertutup ke cubluk",
  "8": "Saluran tertutup ke tangki septik",
  "9": "Saluran perpipaan (komunal / kawasan) ke IPAL",
  "10": "Tidak tahu / tidak ada",
};

export const tempatBABKeyValue: KeyValue = {
  "0": "-",
  "1": "Jamban sendiri",
  "2": "Jamban tetangga / saudara",
  "3": "MCK umum",
  "4": "Sungai / selokan / got / laut",
  "5": "Tanah kosong",
  "6": "Kantong kresek",
  "7": "Lainnya",
};

export const jenisJambanKeyValue: KeyValue = {
  "0": "-",
  "1": "Jamban leher angsa",
  "2": "Jamban bukan leher angsa",
};

export const saluranBuangBlackWaterKeyValue: KeyValue = {
  "0": "-",
  "1": "Saluran terbuka ke riool / selokan / sungai",
  "2": "Saluran terbuka ke kolam / rawa / genangan",
  "3": "Saluran terbuka ke cubluk",
  "4": "Saluran terbuka ke tangki septik",
  "5": "Saluran tertutup ke riool / selokan / sungai",
  "6": "Saluran tertutup ke kolam / rawa / genangan",
  "7": "Saluran tertutup ke cubluk",
  "8": "Saluran tertutup ke tangki septik",
  "9": "Saluran perpipaan (komunal / kawasan) ke IPAL",
  "10": "Tidak tahu / tidak ada",
};

export const klasifikasiSPALDKeyValue: KeyValue = {
  "0": "-",
  "1": "Skala individual (tangki septik)",
  "2": "Skala komunal",
  "3": "Terpusat",
};

export const standarTangkiSeptikKeyValue: KeyValue = {
  "0": "-",
  "1": "Tangki septik fabrikasi sesuai SNI",
  "2": "Tangki septik konstruksi sendiri sesuai SNI",
};

export const letakBuanganWCKeyValue: KeyValue = {
  "0": "-",
  "1": "Di pekarangan depan",
  "2": "Di pekarangan samping",
  "3": "Di pekarangan belakang",
  "4": "Di bawah garasi",
  "5": "Di dalam rumah",
  "6": "Lainnya",
  "7": "Tidak jelas / tidak tahu",
};

export const waktuTangkiSeptikDibuatKeyValue: KeyValue = {
  "0": "-",
  "1": "Kurang dari 1 tahun lalu",
  "2": "1 - 3 tahun lalu",
  "3": "3 - 5 tahun lalu",
  "4": "Lebih dari 5 tahun lalu",
  "5": "Tidak tahu",
};

export const terakhirTangkiSeptikDisedotKeyValue: KeyValue = {
  "0": "-",
  "1": "0 - 3 tahun lalu",
  "2": "3 - 5 tahun lalu",
  "3": "Lebih dari 5 tahun lalu",
  "4": "Belum pernah disedot / dikosongkan",
  "5": "Tidak tahu",
};

export const keberadaanManholePadaTangkiSeptikKeyValue: KeyValue = {
  "0": "-",
  "1": "Ada, beberapa lubang pipa",
  "2": "Tidak ada",
  "3": "Ada, beberapa manhole",
};

export const bentukPenyedotanTangkiSeptikKeyValue: KeyValue = {
  "0": "-",
  "1": "Oleh mobil tinja dari pemerintah daerah",
  "2": "Dikuras oleh tukang yang disuruh",
  "3": "Dikuras sendiri",
};

export const jarakTangkiSeptikKeSumurTerdekatKeyValue: KeyValue = {
  "0": "-",
  "1": "Kurang dari 10 m",
  "2": "Lebih dari 10 m",
};

export const jarakTempatTinggalTempatBABKeyValue: KeyValue = {
  "0": "-",
  "1": "Kurang dari 10 m",
  "2": "Lebih dari 10 m",
};

export const permohonanBantuanStatusKeyValue: KeyValue = {
  "0": "-",
  "1": "Ya",
  "2": "Tidak",
};

export const permohonanBantuanTahunKeyValue: KeyValue = {
  "0": "-",
  "2010": "2010",
  "2011": "2011",
  "2012": "2012",
  "2013": "2013",
  "2014": "2014",
  "2015": "2015",
  "2016": "2016",
  "2017": "2017",
  "2018": "2018",
  "2019": "2019",
  "2020": "2020",
  "2021": "2021",
  "2022": "2022",
};

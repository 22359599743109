import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  media: {
    height: 200,
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    "& svg": {
      color: 'rgba(255, 255, 255, 0.7)',
      width: '200px',
      height: '200px',
    }
  },
}));

export default useStyles;

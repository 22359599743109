import React from "react";
// import { useAppSelector, useAppDispatch } from "./hooks";
// import { logIn } from "./features/user/userSlice";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";

import Login from "./pages/Login";
import Home from "./pages/Home";
import DatasetList from "./pages/Dataset/List";
import DatasetView from "./pages/Dataset/View";
import SurveyList from "./pages/Survey/List";
import SurveyForm from "./pages/Survey/Form";
import UserList from "./pages/User/List";
import UserProfile from "./pages/User/Profile";
import Dashboard from "./pages/Dashboard";
import PendataanOverview from "./pages/Pendataan/Overview";
import PendataanList from "./pages/Pendataan/List";
import PendataanDetail from "./pages/Pendataan/Detail";

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import styles from "./styles";
import { CircularProgress } from "@material-ui/core";
import { useRecoilState } from "recoil";
import { pageVariablesAtom } from "./global-states";
import { ConfirmProvider } from "material-ui-confirm";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: styles.color.main,
      dark: styles.color.dark,
    },
  },
  overrides: {
    MuiPaper: {
      root: {},
      elevation1: {
        boxShadow: styles.boxShadow.elevation1,
      },
      elevation4: {
        boxShadow: styles.boxShadow.elevation4,
      },
    },
    MuiCardHeader: {
      root: {
        borderBottom: `1px solid ${styles.color.border.light}`,
      },
      content: {
        "& .MuiTypography-h5": {
          fontSize: "1rem",
          fontWeight: "bold",
        },
      },
    },
    MuiBreadcrumbs: {
      li: {
        fontSize: "0.8125rem",
        "& >a": {
          color: styles.color.main,
          textDecoration: "none",
        },
        "& >p": {
          fontSize: "0.8125rem",
        },
      },
      separator: {
        fontSize: "0.8125rem",
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const isAuthenticated = () => {
  return !!localStorage.getItem("token"); // For example, using a token stored in localStorage
};

const App = () => {
  const classes = useStyles();

  const [pageVariables] = useRecoilState(pageVariablesAtom);
  return (
    <div className={classes.root} style={{ color: styles.color.text }}>
      <ThemeProvider theme={theme}>
        <ConfirmProvider>
          <CssBaseline />
          {pageVariables.showLoading === true && (
            <CircularProgress
              size={90}
              thickness={8}
              style={{
                position: "fixed",
                left: "50%",
                marginLeft: -45,
                top: "50%",
                marginTop: -45,
              }}
            />
          )}
          <Router>
            <Switch>
              <Route exact path="/" component={Login} />
              {isAuthenticated() ? (
                <>
                  <Route path="/home" component={Home} />
                  <Route path="/dashboard" component={Dashboard} />
                  <Route path="/dataset/:key" component={DatasetView} />
                  <Route path="/dataset" component={DatasetList} />
                  <Route path="/survey/:key" component={SurveyForm} />
                  <Route path="/survey" component={SurveyList} />
                  <Route path="/user/profile" component={UserProfile} />
                  <Route path="/user" component={UserList} />

                  <Route
                    path="/pendataan/overview"
                    component={PendataanOverview}
                  />
                  <Route path="/pendataan/list" component={PendataanList} />
                  <Route
                    path="/pendataan/detail/:id"
                    component={PendataanDetail}
                  />
                </>
              ) : (
                <Route path="/" component={Login} />
              )}
            </Switch>
          </Router>
        </ConfirmProvider>
      </ThemeProvider>
    </div>
  );
};

export default App;

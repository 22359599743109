import React, {useEffect, useRef, useState} from "react";
// import Paper from "@material-ui/core/Paper";

import CommonWrapper from "../../../components/Pages/CommonWrapper";
import axios from "axios";
import {useRecoilState} from "recoil";
import {userDetailsAtom} from "../../../global-states";
import {
  Box,
  Grid,
  Button,
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent
} from "@mui/material";
import {Link, useLocation} from "react-router-dom";
import {apiURL, kelurahanKeyValue} from "../../../variables";
import {iconMarkerStyle, layers, map, radiusCircleStyle} from "./map-functions";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import {Feature} from "ol";
import {Circle, Point} from "ol/geom";

import { useConfirm } from 'material-ui-confirm';

interface RouteParams {
  kode_wilayah: string,
}

interface SurveiSummaryItem {
  id: number,
  nama_kec: string,
  kode_kec: string,
  nama_kel: string,
  kode_kel: string,
  poi_rw: string,
  poi_rt: string,
  responden_nama: string,
  poi_nama_bangunan: string | null,
  pemanfaatan_bangunan: number,
  poi_x_longitude: number,
  poi_y_latitude: number,
  permohonan_bantuan_status: number | null,
}

const PendataanList = () => {
  const [basemap, setBasemap] = useState<number>(0)
  const handleChangeBasemap = (e: SelectChangeEvent) => setBasemap(parseInt(e.target.value))
  const params = new URLSearchParams(useLocation().search);
  const kode_kelurahan = params.get('kode_wilayah');
  const kelurahan = kode_kelurahan !== null ? kelurahanKeyValue[kode_kelurahan] : '';
  const mapContainerRef = useRef<HTMLDivElement>(null);

  const confirm = useConfirm();

  const breadcrumbs = [
    {
      label: 'Home',
      path: '/home',
    },
    {
      label: 'Rekapitulasi Pendataan',
      path: '/pendataan/overview',
    },
    {
      label: `Kelurahan ${kelurahan}`,
      path: '',
    },
  ];


  const [userDetails,] = useRecoilState(userDetailsAtom);
  const [surveiSummaryItems, setSurveiSummaryItems] = useState<SurveiSummaryItem[]>([]);

  const getListFromAPI = () => {
    console.log(userDetails);
    axios.get(`${apiURL}/pendataan/survei?kode_wilayah=${params.get('kode_wilayah')}&surveyor_id=0`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((result) => {
      let items: SurveiSummaryItem[] = result.data["data"];
      setSurveiSummaryItems(items);
      console.log(items);
      initMap(items);
    }).catch((err) => {

    }).finally(() => {

    });
  }

  const deleteUsingAPI = (id: number) => {
    console.log("Delete using API");
    axios.delete(`${apiURL}/pendataan/survei/${id}`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`,
      },
    }).then((result) => {
      console.log(result);
    }).catch((err) => {

    }).finally(() => {
      getListFromAPI();
    });
  }

  const initMap = (items: SurveiSummaryItem[]) => {
    if (mapContainerRef.current !== null) {

      map.getLayers().forEach((baseLayer, baseLayerNumber) => {
        console.log(baseLayerNumber, map.getLayers().getLength())
        if(baseLayerNumber === basemap) {
          baseLayer.setVisible(true)
        } else {
          if(baseLayerNumber < 3) {
            baseLayer.setVisible(false)
          }
        }
      })

      const features = items.map((item) => {
        return new Feature({
          geometry: new Point([item.poi_x_longitude, item.poi_y_latitude]),
          name: 'marker',
        });
      });

      map.setTarget(mapContainerRef.current);
      map.getView().setCenter([106.695596, -6.290138]);

      layers['circle'] = new VectorLayer({
        source: new VectorSource({
          features: [new Feature({
            geometry: new Circle(
              [106.695596, -6.290138],
              0.009 * 5,
            ),
            name: 'radiius',
          })],
        }),
        style: radiusCircleStyle,
      });
      map.addLayer(layers['circle']);

      layers['marker'] = new VectorLayer({
        source: new VectorSource({
          features: features,
        }),
        style: (feature) => {
          // console.log("this is feature", feature);
          return iconMarkerStyle;
        }
      });
      map.addLayer(layers['marker']);

    }
  }

  const deleteEntry = (id: number) => {
    confirm({
      title: 'Menghapus Data',
      description: `Anda yakin akan menghapus data ini ?`,
      confirmationText: 'Ya',
      cancellationText: 'Tidak',
    }).then((x) => {
      console.log("delete", id);
      deleteUsingAPI(id);
      }).catch(() => {

    });
  }

  // Component did mount
  useEffect(() => {
    getListFromAPI();
  }, [basemap]);

  return (
    <CommonWrapper path="/pendataan/list" title={`Kelurahan ${kelurahan}`} breadcrumbs={breadcrumbs}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <CardHeader title={<Grid container direction={`row`}>
                <Grid item sx={{flexGrow: 1,}}>
                  Titik Lokasi Pendataan
                </Grid>
                <Grid item sx={{width: 200}}>
                  <FormControl fullWidth>
                    <InputLabel >Pilih Basemap</InputLabel>
                    <Select
                      id="demo-simple-select"
                      value={basemap.toString()}
                      label="Pilih Basemap"
                      onChange={handleChangeBasemap}
                      size={`small`}
                    >
                      <MenuItem value={0}>Imagery</MenuItem>
                      <MenuItem value={1}>Topo</MenuItem>
                      <MenuItem value={2}>Road</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
            </Grid>} />
              <div ref={mapContainerRef} id="mapContainer" style={{height: 400}}></div>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardHeader title={`Rekapitulasi Pendataan Kelurahan`}/>
              <CardContent>
                <TableContainer>
                  <Table size="small" stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>RW</TableCell>
                        <TableCell>RT</TableCell>
                        <TableCell>Nama Responden</TableCell>
                        <TableCell>Nama Bangunan</TableCell>
                        <TableCell>Status Permohonan Bantuan SPALD</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {surveiSummaryItems.length > 0 &&
                      surveiSummaryItems.map((item, key) => {
                        return (
                          <TableRow key={key}>
                            <TableCell>{item.poi_rw}</TableCell>
                            <TableCell>{item.poi_rt}</TableCell>
                            <TableCell>
                              <Button onClick={() => {
                                deleteEntry(item.id);
                              }} style={{marginRight: 8,}} size={`small`} color={`secondary`} variant={`contained`}>Hapus</Button>
                              <Link style={{textTransform: 'capitalize'}} to={`/pendataan/detail/${item.id}`}>{item.responden_nama}</Link>
                            </TableCell>
                            <TableCell>{item.poi_nama_bangunan}</TableCell>
                            <TableCell>{item.permohonan_bantuan_status}</TableCell>
                          </TableRow>
                        )
                      })
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
    </CommonWrapper>
  );
};

export default PendataanList;

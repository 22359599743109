import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import axios from "axios";
import {useRecoilState} from "recoil";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import {pageVariablesAtom, userDetailsAtom} from "../../global-states";

import {makeStyles} from "@material-ui/core/styles";
import logoKotaTangsel from "../../assets/logo-kota-tangerang-selatan.svg";
import {apiURL} from "../../variables";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginTop: theme.spacing(10),
  },
  item: {
    flexGrow: 1,
  },
  paper: {
    width: theme.spacing(60),
    padding: theme.spacing(2),
  },
}));

const Login = () => {
  const history = useHistory();
  const classes = useStyles();
  const [, setUserDetails] = useRecoilState(userDetailsAtom);
  const [pageVariables, setPageVariables] = useRecoilState(pageVariablesAtom);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const doLogin = () => {
    const url = '/api/v1/login';
    setPageVariables({...pageVariables, showLoading: true});

    axios.post(`${apiURL}/login`, {
      identifier: username,
      pw: password,
    }).then((result) => {
      const data = result.data["data"];
      const userSummary = data["user_summary"];
      const userDetailsPayload = {
        user_id: userSummary["id"],
        role_type_id: userSummary["role_type_id"],
        username: userSummary["username"],
        fullname: userSummary["fullname"],
        token: data["token"],
      };

      setUserDetails(userDetailsPayload);
      localStorage.setItem('userDetails', JSON.stringify(userDetailsPayload));
      localStorage.setItem('token', userDetailsPayload.token);

      history.push("/home");
    }).catch((err) => {
    }).finally(() => {
      setUsername("");
      setPassword("");
      setPageVariables({...pageVariables, showLoading: false});
    });

    /*
    *
    if(username === "superadmin" && password === "P3muk1m4n*") {
      setUserDetails({
        user_id: 1,
        role_type_id: 1,
        username: "superadmin",
        fullname: "Tono Baskito",
      });
      history.push("/home");
    } else if (username === "viewer" && password === "P3muk1m4n*") {
      setUserDetails({
        user_id: 2,
        role_type_id: 4,
        username: "viewer",
        fullname: "Bambang Jurnalis",
      });
      history.push("/home");
    } else {

    }
    *
    * */
  };

  return (
    <div className={classes.root}>
      <Grid container direction="column" justify="center" alignItems="center" spacing={3}>
        <Grid item>
          <Grid container direction="column" justify="center" alignItems="center" spacing={1}>
            <Grid item>
              <img src={logoKotaTangsel} alt="Logo Kota Tangerang Selatan" width="200" />
            </Grid>
            <Grid item>
              <Typography variant="subtitle1">Selamat Datang !</Typography>
            </Grid>
            <Grid item>
              <Typography variant="h2">Portal SIPALDO</Typography>
            </Grid>
            <Grid item>
              <Typography variant="h4">(Sistem Informasi Pengolahan Air Limbah Domestik)</Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Paper className={classes.paper}>
            <Grid container spacing={2}>
              <Grid item className={classes.item}>
                <TextField 
                  margin="dense"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  fullWidth
                  variant="outlined"
                  label="Username"
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item className={classes.item}>
                <TextField
                  margin="dense"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  fullWidth
                  variant="outlined"
                  label="Password"
                  type="password"
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item className={classes.item}>
                <Button
                  fullWidth
                  variant="contained"
                  type="button"
                  onClick={() => doLogin()}
                  color="primary"
                >
                  Login
                </Button>
              </Grid>
              <Grid item className={classes.item}>
                <Button
                  fullWidth
                  variant="contained"
                  type="button"
                  color="default">
                  Registrasi
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default Login;

import React, { useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Drawer from "@material-ui/core/Drawer";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
// import Hidden from "@material-ui/core/Hidden";
import clsx from "clsx";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
// import Divider from "@material-ui/core/Divider";
import { Link } from "react-router-dom";

import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faChartLine,
  faChevronCircleLeft,
  faDatabase,
  faHome,
  faTabletAlt,
  faUser,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";

import logoKotaTangsel from "../../../assets/logo-kota-tangerang-selatan.svg";
import styles from "../../../styles";

type BreadcrumbsItem = {
  label: string;
  path: string;
};

type Props = {
  path: string;
  title: string;
  children: JSX.Element;
  breadcrumbs: BreadcrumbsItem[];
};

type MenuItem = {
  label: string;
  icon: typeof faBars;
  path: string;
  depth: number;
};

const menuItemList: MenuItem[] = [
  {
    label: "Home",
    icon: faHome,
    path: "/home",
    depth: 1,
  },
  // {
  //   label: "Dashboard",
  //   icon: faChartLine,
  //   path: "/dashboard",
  //   depth: 1,
  // },
  {
    label: "Dataset",
    icon: faDatabase,
    path: "/dataset",
    depth: 1,
  },
  // {
  //   label: "Formulir Survei",
  //   icon: faTabletAlt,
  //   path: "/survey/pengolahan-air-limbah",
  //   depth: 1,
  // },
  // {
  //   label: "Kelola User",
  //   icon: faUsers,
  //   path: "/user",
  //   depth: 1,
  // },
];

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${theme.spacing(30)}px)`,
    marginLeft: theme.spacing(30),
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    flexShrink: 0,
    width: drawerWidth,
  },
  drawerPaper: {
    background: theme.palette.primary.dark,
    color: theme.palette.background.paper,
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    boxShadow:
      "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
    alignItems: "center",
    borderBottom: "null",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
    "& button": {
      color: theme.palette.background.paper,
    },
  },
  listItem: {},
  listItemIcon: {
    width: theme.spacing(4),
  },
  listItemText: {},
  content: {
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  title: {
    flexGrow: 1,
  },
  breadcrumbs: {},
  children: {
    "& a": {
      textDecoration: `none`,
      color: styles.color.main,
    },
  },
  pageTitle: {
    fontWeight: 700,
  },
}));

const CommonWrapper = ({ path, title, children, breadcrumbs }: Props) => {
  const classes = useStyles();

  const [drawerIsOpen, setDrawerIsOpen] = useState(true);

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: drawerIsOpen,
        })}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => setDrawerIsOpen(true)}
            className={clsx(classes.menuButton, drawerIsOpen && classes.hide)}
          >
            <FontAwesomeIcon icon={faBars} />
          </IconButton>
          <Box mr={2}>
            <Link to="/home">
              <img
                src={logoKotaTangsel}
                alt="Logo Kota Tangerang Selatan"
                height="40"
              />
            </Link>
          </Box>
          <Box mx={1} className={classes.title}>
            <Typography variant="h6">SIPALDO</Typography>
          </Box>
          {/* <IconButton
            component={Link}
            to="/user/profile"
            color="inherit"
            aria-label="menu"
          > */}
          <FontAwesomeIcon icon={faUser} />
          {/* </IconButton> */}
        </Toolbar>
      </AppBar>

      <Drawer
        variant="persistent"
        open={drawerIsOpen}
        className={classes.drawer}
        classes={{ paper: classes.drawerPaper }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={() => setDrawerIsOpen(false)}>
            <FontAwesomeIcon icon={faChevronCircleLeft} />
          </IconButton>
        </div>
        <div>
          <List>
            {menuItemList.map((item, key) => {
              return (
                <ListItem
                  key={key.toString()}
                  button
                  component={Link}
                  to={item.path}
                >
                  <ListItemText className={classes.listItem}>
                    <Grid container spacing={2}>
                      <Grid item className={classes.listItemIcon}>
                        <FontAwesomeIcon icon={item.icon} />
                      </Grid>
                      <Grid item className={classes.listItemText}>
                        <Typography>{item.label}</Typography>
                      </Grid>
                    </Grid>
                  </ListItemText>
                </ListItem>
              );
            })}
          </List>
        </div>
      </Drawer>

      <main
        className={clsx(classes.content, {
          [classes.contentShift]: drawerIsOpen,
        })}
      >
        <Box mt={12} mb={2} px={4} className={classes.breadcrumbs}>
          <Breadcrumbs>
            {breadcrumbs.map((item, key) => {
              if (item.path === "") {
                return (
                  <Typography key={key.toString()}>{item.label}</Typography>
                );
              } else {
                return (
                  <Link to={item.path} key={key.toString()}>
                    {item.label}
                  </Link>
                );
              }
            })}
          </Breadcrumbs>
        </Box>
        <Box px={4} mb={3} className={classes.pageTitle}>
          <Typography variant="h4" component="h1">
            {title}
          </Typography>
        </Box>
        <Box className={classes.children} px={4}>
          {children}
        </Box>
      </main>
    </div>
  );
};

export default CommonWrapper;

import React from "react";
// import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import {Link} from "react-router-dom";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDatabase} from "@fortawesome/free-solid-svg-icons";

import {MenuItem} from "../../../types/Type";

import CommonWrapper from "../../../components/Pages/CommonWrapper";

const menuItemList: MenuItem[] = [
  {
    label: "Pengelolaan Air Limbah Domestik",
    icon: faDatabase,
    path: "/dataset/pengolahan-air-limbah",
    depth: 1,
  },
  {
    label: "Sanitasi Lingkungan",
    icon: faDatabase,
    path: "/dataset/pengolahan-lainnu",
    depth: 1,
  },
  {
    label: "Air Minum",
    icon: faDatabase,
    path: "/dataset/pengolahan-lainnu",
    depth: 1,
  },
  {
    label: "Penataan Bangunan",
    icon: faDatabase,
    path: "/dataset/pengolahan-tersebut",
    depth: 1,
  },
];

const breadcrumbs=[
  {
    label: 'Home',
    path: '/home',
  },
  {
    label: 'Dataset',
    path: '',
  },
];

const useStyles = makeStyles((theme) => ({
  root: {},
  listItem: {},
  listItemIcon: {
    width: theme.spacing(4),
  },
  listItemText: {},
}));

const DatasetList = () => {
  const classes = useStyles();
  return (
    <CommonWrapper path="/dataset" title="Dataset" breadcrumbs={breadcrumbs}>
      <Card>
        <CardHeader title="Semua Dataset" />
          <List>
            {menuItemList.map((item, key) => {
              return (
                <ListItem button component={Link} to={item.path} key={key.toString()}>
                  <ListItemText className={classes.listItem}>
                    <Grid container spacing={2}>
                      <Grid item className={classes.listItemIcon}>
                        <FontAwesomeIcon icon={item.icon} />
                      </Grid>
                      <Grid item className={classes.listItemText}>
                        <Typography>{item.label}</Typography>
                      </Grid>
                    </Grid>
                  </ListItemText>
                </ListItem>
              );
            })}
          </List>
      </Card>
    </CommonWrapper>
  );
};

export default DatasetList;

import {
  faChartBar,
  faChartLine,
  faUsers,
  faDatabase,
  faTabletAlt,
  faMapMarkedAlt,
} from "@fortawesome/free-solid-svg-icons";

import { MediaItem } from "../../types/Type";

export const mediaItemList: MediaItem[] = [
  // {
  //   title: "Dashboard",
  //   imagePath: "/home",
  //   description: "",
  //   backgroundColor: "#f6bb00",
  //   icon: faChartLine,
  // },
  {
    title: "Form Pendataan",
    imagePath: "/survey/pengolahan-air-limbah",
    description: "",
    backgroundColor: "#da251d",
    icon: faTabletAlt,
  },
  {
    title: "Hasil Pendataan",
    imagePath: "/pendataan/overview",
    description: "",
    backgroundColor: "#00aa90",
    icon: faDatabase,
  },
  {
    title: "Visualisasi Grafik",
    imagePath: "/dataset/pengolahan-air-limbah",
    description: "",
    backgroundColor: "#007d3c",
    icon: faChartBar,
  },
  // {
  //   title: "Peta Interaktif",
  //   imagePath: "/dataset/pengolahan-air-limbah",
  //   description: "",
  //   backgroundColor: "#0094bb",
  //   icon: faMapMarkedAlt,
  // },
  // {
  //   title: "Pengelolaan User",
  //   imagePath: "/",
  //   description: "",
  //   backgroundColor: "#551d35",
  //   icon: faUsers,
  // },
];

import { atom } from "recoil";
// import { UserDetails } from "./types/Type";
// type UserDetails = { username: string, fullname: string, role_type_id: number, user_id: number, };

const userDetailsAtom = atom({
  key: 'userDetailsAtom',
  default: {
    user_id: 0,
    role_type_id: 0,
    username: "",
    fullname: "",
    token: "",
  },
});

const pageVariablesAtom = atom({
  key: 'pageVariablesAtom',
  default: {
    showLoading: false,
  },
});

export { userDetailsAtom, pageVariablesAtom };

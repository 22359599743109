import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  chartContainer: {
    height: theme.spacing(30),
  },
  mapContainer: {
    "& #mapContainer": {
      height: theme.spacing(60),
      padding: 0,
      paddingBottom: 0,
    },
  },
  mapPopup: {
    position: 'absolute',
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    minWidth: theme.spacing(40),
  },
}));

export default useStyles;
